import React from 'react'
import {
    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    RedditShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    EmailShareButton,

    FacebookIcon,
    TwitterIcon,
    GooglePlusIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,

} from 'react-share';

import './SocialIcons.scss'

const SocialIcons = ({shareUrl, title, description}) => {


    return (
        <div className="SocialIcons">
            <EmailShareButton
                url={shareUrl}
                subject={title}
                body={description}
                className="">
                <EmailIcon
                    size={60} round={false}/>
            </EmailShareButton>

            <FacebookShareButton
                url={shareUrl}
                quote={description}
                className="">
                <FacebookIcon
                    size={60} round={false}/>
            </FacebookShareButton>

            <WhatsappShareButton
                url={shareUrl}
                title={description}
                className="">
                <WhatsappIcon
                    size={60} round={false}/>
            </WhatsappShareButton>

            <LinkedinShareButton
                url={shareUrl}
                title={title}
                description={description}
                className="">
                <LinkedinIcon
                    size={60} round={false}/>
            </LinkedinShareButton>

            <TwitterShareButton
                url={shareUrl}
                title={description}
                className="">
                <TwitterIcon
                    size={60} round={false}/>
            </TwitterShareButton>
        </div>
    )


}

export default SocialIcons