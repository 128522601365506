import React, {useEffect} from 'react'
import ReactMarkdown from "react-markdown";
import SocialIcons from "./ShareIcons";

const FinishPage = ({content, score, nrQuestions, scoreText, shareText, avgScore}) => {


    // convert database avgScore (scale 0-10) to nrQuestions
    avgScore = Math.round(avgScore / 10 * nrQuestions)

    return (
        <div className="Page">

            <div className="body container">
                <ReactMarkdown source={content}/>
                <ReactMarkdown source={`Je hebt **${score} van de ${nrQuestions}** vragen goed. \nDe gemiddelde score is ${avgScore}.`}/>
                <ReactMarkdown source={scoreText}/>
            </div>

            <div className="footer">
                <SocialIcons shareUrl={document.location.href} title={document.title} description={shareText}/>
            </div>
        </div>
    )
}

export default FinishPage
