import React, {Component} from 'react'
import Hammer from 'hammerjs'
import ReactDOM from 'react-dom'
import SimpleCard from './SimpleCard'
import {translate3d} from './utils'

class DraggableCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            x: 0,
            y: 0,
            initialPosition: {x: 0, y: 0},
            startPosition: {x: 0, y: 0},
            animation: null,
            pristine: true,
            dragging: false,
            dragDirection: null,
        }
    }

    componentDidMount() {
        this.hammer = new Hammer.Manager(ReactDOM.findDOMNode(this))
        this.hammer.add(new Hammer.Pan({threshold: 2}))

        this.hammer.on('panstart panend pancancel panmove', this.handlePan)
        this.hammer.on('swipestart swipeend swipecancel swipemove', this.handleSwipe)



        this.resetPosition()
        window.addEventListener('resize', this.resetPosition)

    }


    resetPosition = () => {

        const {x, y} = this.props.containerSize
        const card = ReactDOM.findDOMNode(this)

        const initialPosition = {
            x: Math.round((x - card.offsetWidth) / 2),
            y: 0
        }

        this.setState(state => ({
            ...state,
            x: initialPosition.x,
            y: initialPosition.y,
            initialPosition: initialPosition,
            startPosition: {x: 0, y: 0},
            dragging: false,
        }))
    }

    panstart() {
        const {x, y} = this.state
        this.setState(state => ({
            ...state,
            animation: false,
            startPosition: {x, y},
            pristine: false,
            dragging: true,
        }))
    }


    getDirection() {
        const screen = this.props.containerSize
        const card = ReactDOM.findDOMNode(this)

        const {x, initialPosition} = this.state

        const xDiff = x - initialPosition.x
        // console.warn(xDiff)

        switch (true) {
            // case (this.state.x < -50):
            //     return 'Left'
            // case (this.state.x + (card.offsetWidth - 50) > screen.x):
            //     return 'Right'
            case xDiff < -20:
                // console.warn('return left')
                return 'Left'
            case xDiff > 20:
                // console.warn('return right')
                return 'Right'

            // case (this.state.y < -50):
            //     console.warn('return top')
            //     return 'Top'
            // case (this.state.y + (card.offsetHeight - 50) > screen.y):
            //     console.warn('return bottom')
            //     return 'Bottom'
            default:
                // console.warn('return none')
                return false
        }
    }


    panend(ev) {
        // console.warn('panend', ev)

        const direction = this.getDirection()
        // console.warn(' -> panend got direction', direction)

        if (this.props[`onSwipe${direction}`]) {
            this.props[`onSwipe${direction}`]()
            // console.warn(`!! calling onOutScreen${direction}`)
            this.props[`onOutScreen${direction}`](this.props.index)
        } else {
            // console.warn(`!! calling reset`)
            this.resetPosition()
            this.setState(state => ({...state, animation: true}));
        }
    }

    panmove(ev) {

        // console.trace()

        // console.warn('panmove', ev)
        const direction = this.getDirection()

        const {dragDirection} = this.state
        if (dragDirection !== direction) {
            this.setState({
                dragDirection: direction
            })
        }

        // console.warn('direction ', direction)
        this.setState(this.calculatePosition(ev.deltaX, ev.deltaY))
    }

    pancancel(ev) {
        console.log(ev.type)
    }

    handlePan = (ev) => {
        ev.preventDefault()
        this[ev.type](ev)
        return false
    }

    handleSwipe(ev) {
        console.log(ev.type)
    }

    calculatePosition(deltaX, deltaY) {
        const {initialPosition: {x, y}} = this.state
        return {
            x: (x + deltaX),
            y: (y + deltaY)
        }
    }


    componentWillUnmount() {
        if (this.hammer) {
            this.hammer.stop()
            this.hammer.destroy()
            this.hammer = null
        }
        window.removeEventListener('resize', this.resetPosition)
    }

    setCardClassName = (animation, dragging) => {
        return `${animation ? 'animate' : ''} ${dragging ? 'dragging' : ''}`;

    }

    render() {
        const {props, setCardClassName, state} = this;
        const {x, y, animation, pristine, dragging, initialPosition, dragDirection} = state
        const r = (initialPosition.x - x) / 20
        const style = translate3d(x, y, r)
        // style.rotate

        return <SimpleCard {...props} style={style}
                           className={`DraggableCard drag-${dragDirection} ${setCardClassName(animation, dragging)}`}/>
    }
}

export default DraggableCard
